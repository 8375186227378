import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import CampaignCallout, {
  SCampaignCallout,
} from '../components/contentBlocks/CampaignCallout';

const CancerAwareness = ({ path }) => (
  <Layout title="Cancer Awareness" path={path}>
    <Awareness>
      <CampaignCallout />
    </Awareness>
  </Layout>
);

export default CancerAwareness;

const Awareness = styled.div`
  ${SCampaignCallout} {
    min-height: calc(100vh - 235px);
    display: flex;
    align-items: center;
  }
`;
